'use client';

import { useRouter } from "next/navigation";
import { useState } from "react";

export function ArticleListCategories() {
  const router = useRouter()
  const [categoryHandle, setCategoryHandle] = useState<string>('')
  const handleSubmit = () => {
    if (categoryHandle === '') {
      return
    }
    router.push(`/blog/category/${categoryHandle}`)
  }
  return (
    <div className="flex flex-col md:flex-row md:items-center md:px-10 justify-end items-start mt-6 gap-4">
      <span className="hidden md:block font-bold text-[#494949]">Categories:</span>
      <select className="border-t-0 border-x-0 border-b-2 border-fp-green text-fp-green w-full md:w-fit" name="" id="" onChange={(e) => {setCategoryHandle(e.target.value);}}>
        <option value="">Category</option>
        <option value="newsletter">Newsletter</option>
        <option value="home-security-systems">Home Security Systems</option>
        <option value="seasonal">Seasonal</option>
        <option value="home-automation">Home Automation</option>
        <option value="home-safety">Home Safety</option>
        <option value="environmental-sensors">Environmental Sensors</option>
        <option value="frontpoint-special-feature">Frontpoint Special Feature</option>
        <option value="in-the-news">In the News</option>
      </select>
      <button onClick={()=>handleSubmit()} className="font-bold px-6 justify-center w-full md:w-fit flex items-center flex-nowrap bg-fp-green hover:bg-white hover:border-fp-green hover:border-2 min-w-24 min-h-10 text-white hover:text-fp-green">
        Apply
      </button>
      
    </div>
  );
}