"use client";

import { Pagination } from "flowbite-react";
import { useState } from "react";

import type { CustomFlowbiteTheme } from "flowbite-react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { cn } from "utils/cn";

const customTheme: CustomFlowbiteTheme = {
  "pagination":{
    "pages":{
        "previous": {
            "base": "ml-0 rounded-l-lg border-none border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
            "icon": "h-5 w-5"
        },
        "next": {
            "base": "rounded-r-lg border-none border-gray-300 bg-white px-3 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
            "icon": "h-5 w-5"
        },
        "selector": {
            "base": "w-12 border-none border-gray-300 bg-white py-2 leading-tight text-gray-500 enabled:hover:bg-fp-light-gray enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
            "active": "bg-fp-light-gray text-fp-gray hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white",
            "disabled": "cursor-not-allowed opacity-50"
        }
    }
  }
};

export function ArticleListPagination() {
  const searchParams = useSearchParams();
  const [currentPage, setCurrentPage] = useState(Number(searchParams?.get('page')) || 1);
  const pathname = usePathname();
  const { replace } = useRouter();
  const onPageChange = (page: number) => {
      setCurrentPage(page);
      const params = new URLSearchParams(searchParams);
      params.set("page", page.toString());
    replace(`${pathname}?${params.toString()}`, { scroll: true });
  }

  return (
    <div className={cn("flex overflow-x-auto sm:justify-center", {'hidden': searchParams.get('search')})}>
      <Pagination
        theme={customTheme.pagination}
        layout="pagination"
        currentPage={currentPage}
        totalPages={1000}
        onPageChange={onPageChange}
        previousLabel=""
        nextLabel=""
        showIcons
      />
    </div>
  );
}