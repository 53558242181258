'use client'
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useDebouncedCallback } from 'use-debounce';
export function ArticleListSearch(){
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const { replace } = useRouter();

    const handleSearch = useDebouncedCallback((term) => {
      const params = new URLSearchParams(searchParams);
      if (term) {
        params.set('search', term);
      } else {
        params.delete('search');
      }
      params.delete('page');
      replace(`/blog?${params.toString()}`);
    }, 500);

    return (
        <div className="flex">
            <input className="w-full" type="text" placeholder="Search Blog"  
                onChange={(e) => {
                    handleSearch(e.target.value);
                }}
              defaultValue={searchParams.get('search')?.toString()}
            />
        </div>
    )
}